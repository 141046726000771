import React from 'react'
import '../css/virtual-tour.css'

const VirtualTour = () => {
    return (
        <section className='virtual-tour-section'>
            <iframe
                src="https://www.google.com/maps/embed?pb=!4v1735537917757!6m8!1m7!1sCAoSLEFGMVFpcFBBam8yc0stRXFlbHRrUlI1bFlFTUlhWWpqUFhHcS1UMXpxcFNr!2m2!1d29.32332830314266!2d79.14220365077209!3f200.82384369899526!4f3.9553383423695436!5f0.4000000000000002"
                width="100%"
                height="500px"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
        </section>
    )
}

export default VirtualTour
