import React from 'react'
import { Helmet } from 'react-helmet-async'
import Navbar from '../components/navbar'
import TopBanner from '../components/top-banner'
import Footer from '../components/footer'
import SocialMediaFixed from '../components/socialmedia-fixed'
import LuxuryCottageComp from '../components/luxury-cottage'

const LuxuryCottagePage = () => {

    return (
        <>
            <Helmet>
                <title>Luxury Cottage - Vanasthali Jungle Resort</title>
                <meta name="description" content="Stay in the exclusive Luxury Cottage at Vanasthali Jungle Resort. Enjoy serene jungle views, premium amenities, and an unforgettable retreat in Corbett." />
                <meta name="keywords" content="Luxury Cottage, Vanasthali Jungle Resort, Corbett luxury stay, jungle resort Corbett, premium cottages Corbett, Corbett accommodations" />
                <link rel="canonical" href="https://vanasthalicorbett.com/rooms/luxury-cottage" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "HotelRoom",
                        "name": "Luxury Cottage",
                        "url": "https://vanasthalicorbett.com/rooms/luxury-cottage",
                        "telephone": "+91 8743000197",
                    })}
                </script>
            </Helmet>


            {<TopBanner />}
            {<Navbar />}
            {<LuxuryCottageComp />}
            {<Footer />}
            {<SocialMediaFixed />}
        </>
    )
}

export default LuxuryCottagePage
