import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import TopBanner from '../components/top-banner';
import SocialMediaFixed from '../components/socialmedia-fixed';
import SkeletonHero from '../components/skeleton-hero';
import IntroductionSection from '../components/introduction-section';
import WhatWeOffer from '../components/what-we-offer';
import Accommodation from '../components/accommodation';
import GuestReviews from '../components/guest-reviews';
const HeroSection = React.lazy(() => import("../components/hero-section"));

const HomePage = () => {
    return (
        <>
            <Helmet>
                <title>Vanasthali Jungle Resort - Luxury Stay in Jim Corbett National Park</title>
                <meta name="description" content="Experience the beauty of nature at Vanasthali Jungle Resort, located in Jim Corbett National Park. Enjoy comfortable accommodations, thrilling wildlife safaris, and serene jungle views in one of India's top wildlife destinations." />
                <meta name="keywords" content="Vanasthali Jungle Resort, Jim Corbett Resort, luxury jungle resort, wildlife resort, eco-friendly resort, Jim Corbett National Park, nature resort, adventure resort, wildlife safari, Ramnagar resorts, nature stay Corbett, jungle lodge, wildlife experiences, Corbett National Park resort, jungle stay Jim Corbett, eco-tourism Corbett" />
                <link rel="canonical" href="https://vanasthalicorbett.com" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Hotel",
                        "name": "Vanasthali Jungle Resort - Luxury Stay in Jim Corbett National Park",
                        "telephone": "+91 8743000197",
                        "url": "https://vanasthalicorbett.com"
                    })}
                </script>
            </Helmet>
            <TopBanner />
            <Navbar />
            <React.Suspense fallback={<SkeletonHero />}>
                <HeroSection />
            </React.Suspense>
            <IntroductionSection />
            <Accommodation />
            <WhatWeOffer />
            <GuestReviews />
            <Footer />
            <SocialMediaFixed />
        </>
    );
};

export default HomePage;
