import React from "react";
import "../css/why-choose.css";
import "../css/premium-room-comp.css";
import { useNavigate } from "react-router-dom";
import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import image1 from "../assets/room/luxury-cottage/luxury-cottage1.jpg";
import image2 from "../assets/room/luxury-cottage/luxury-cottage2.jpg";
import image3 from "../assets/room/luxury-cottage/luxury-cottage3.jpg";
import image4 from "../assets/room/luxury-cottage/luxury-cottage4.jpg";
import image5 from "../assets/room/luxury-cottage/luxury-cottage5.jpg";



import icon1 from "../assets/icons/double-bed.png";
import icon2 from "../assets/icons/wi-fi.png";
import icon3 from "../assets/icons/dinner.png";
import icon4 from "../assets/icons/tv-monitor.png";
import icon5 from "../assets/icons/parked-car.png";

const LuxuryCottageComp = () => {
  const navigate = useNavigate();
  return (
    <section className="why-choose-section">
      <h1 className="swipe">Luxury Cottage</h1>
      <div className="why-choose-slider">
        <Swiper
          modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          spaceBetween={30}
          slidesPerView={1}
          style={{ maxWidth: "100%", minWidth: "300px", padding: "0px 0px" }}
          pagination={{ clickable: true }}
          navigation={true}
          // breakpoints={{
          //   320: {
          //     navigation: false,
          //   },
          //   480: {
          //     navigation: false,
          //   },
          //   628: {
          //     navigation: false,
          //   },
          // }}
          className="swipe"
        >
          <SwiperSlide>
            <div className="slider-card-testimonial testimonial-content why-choose-slider-image">
              <img src={image1} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider-card-testimonial testimonial-content why-choose-slider-image">
              <img src={image2} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider-card-testimonial testimonial-content why-choose-slider-image">
              <img src={image3} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider-card-testimonial testimonial-content why-choose-slider-image">
              <img src={image4} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider-card-testimonial testimonial-content why-choose-slider-image">
              <img src={image5} alt="" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="why-choose-contents room-services">
        <h2>Room Services</h2>
        <div className="flex">
          <div className="why-choose-contents-box">
            <img src={icon1} alt="" />
            <h2>Double Bed</h2>
          </div>
          <div className="why-choose-contents-box">
            <img src={icon2} alt="" />
            <h2>Free Wifi</h2>
          </div>
          <div className="why-choose-contents-box">
            <img src={icon3} alt="" />
            <h2>Restaurant</h2>
          </div>
          <div className="why-choose-contents-box">
            <img src={icon4} alt="" />
            <h2>LCD TV</h2>
          </div>
          <div className="why-choose-contents-box">
            <img src={icon5} alt="" />
            <h2>Parking</h2>
          </div>
        </div>
      </div>
      <div className="room-overview">
        <h2>Room Overview</h2>
        <div className="overview-table">
          <div className="overview-row">
            <span className="label">Occupancy:</span>
            <span className="value">Two Persons</span>
          </div>
          <div className="overview-row">
            <span className="label">Size:</span>
            <span className="value">250 sq. feet</span>
          </div>
          <div className="overview-row">
            <span className="label">View:</span>
            <span className="value">Himalayan View</span>
          </div>
          <div className="overview-row">
            <span className="label">Room Service:</span>
            <span className="value">Available per request</span>
          </div>
          <div className="overview-row">
            <span className="label">Terraces:</span>
            <span className="value">Balcony</span>
          </div>
          <div className="overview-row">
            <span className="label">Internet Free:</span>
            <span className="value">Yes</span>
          </div>
        </div>
      </div>
      <div className="premium-room-comp-content room-card-price-left ">
        <h2>Luxury Cottage</h2>
        <br />
        <h2>
          Rs. 4499 <span>/ Night</span>
        </h2>
        <p>
          Experience ultimate relaxation in a luxury cottage, where comfort meets elegance amidst breathtaking views and top-tier amenities.
        </p>
        <button
          onClick={() => {
            navigate("/contact");
          }}
        >
          Book Now
        </button>
      </div>
      <div className="room-virtual-view">
        <h2>Virtual Room Tour</h2>
        <br />
        <iframe
          src="https://www.google.com/maps/embed?pb=!4v1735796328102!6m8!1m7!1sCAoSLEFGMVFpcE40Yms3a3dSVW9jRUFuS0lXejFNeWNiY3I2UzliZ3J6WVlCdW5u!2m2!1d29.32312022950119!2d79.14191071253983!3f329.08673543496263!4f3.236165595119161!5f0.7820865974627469"
          width="100%"
          height="500px"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe></div>
    </section>
  );
};

export default LuxuryCottageComp;
