import React from 'react'
import { Helmet } from 'react-helmet-async'
import Navbar from '../components/navbar'
import TopBanner from '../components/top-banner'
import Footer from '../components/footer'
import SocialMediaFixed from '../components/socialmedia-fixed'
import VillaComp from '../components/villa-comp'

const VillaPage = () => {

    return (
        <>
            <Helmet>
                <title>Villa - Vanasthali Jungle Resort</title>
                <meta name="description" content="Experience luxury and privacy in the Villa at Vanasthali Jungle Resort. Perfect for families or groups, enjoy premium amenities and scenic surroundings in Corbett." />
                <meta name="keywords" content="Villa, Vanasthali Jungle Resort, Corbett villa stay, private villa Corbett, luxury accommodation Corbett, family villa in Corbett" />
                <link rel="canonical" href="https://vanasthalicorbett.com/rooms/villa" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "HotelRoom",
                        "name": "Villa",
                        "url": "https://vanasthalicorbett.com/rooms/villa",
                        "telephone": "+91 8743000197",
                    })}
                </script>
            </Helmet>



            {<TopBanner />}
            {<Navbar />}
            {<VillaComp />}
            {<Footer />}
            {<SocialMediaFixed />}
        </>
    )
}

export default VillaPage
