import React, { useState } from "react";
import "../css/faq.css";

const Faq = () => {
  const [visibleAnswers, setVisibleAnswers] = useState({});

  const toggleAnswerVisibility = (index) => {
    setVisibleAnswers((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const faqData = [
    {
      question:
        "What are the total number of rooms in Vanasthali Jungle Resort Corbett?",
      answer:
        "Vanasthali Jungle Resort, Corbett, has a total of 14 rooms. These include luxury villas, family cottages, luxury cottages, and wooden cottages, all designed to offer a comfortable and relaxing stay amidst the serene surroundings of Corbett.",
    },
    {
      question:
        "Is Vanasthali Jungle Resort Corbett managed by any hotel Chain?",
      answer:
        "Yes, Vanasthali Jungle Resort, Corbett, is managed by Royal Collection Hotels & Resorts Pvt. Ltd., ensuring high-quality service and a premium hospitality experience.",
    },
    {
      question:
        "Who is the Sales, Marketing and Reservation Partner of Vanasthali Jungle Resort Corbett?",
      answer:
        "The Sales, Marketing, and Reservation Partner of Vanasthali Jungle Resort, Corbett, is Royal Collection Hotels & Resorts Pvt. Ltd. They handle all promotional and booking activities to ensure a seamless guest experience.",
    },
    {
      question: "Who owns Vanasthali Jungle Resort Corbett?",
      answer:
        "Vanasthali Jungle Resort, Corbett, is owned by Vanasthali Resorts Pvt. Ltd., offering a serene retreat with excellent hospitality in the heart of Corbett.",
    },
    {
      question: "Does Vanasthali Jungle Resort, Corbett host groups?",
      answer:
        "Yes, Vanasthali Jungle Resort, Corbett, is well-equipped to host groups. Whether it’s for family gatherings, corporate outings, or group tours, the resort provides comfortable accommodations and tailored services to ensure a memorable stay.",
    },
    {
      question: "Whom should I get in touch for package related queries?",
      answer: (
        <>
          For package-related queries at Vanasthali Jungle Resort, Corbett,
          please contact:
          <br />
          <br />
          <a href="tel:+918743000197">
            <i className="fa-solid fa-phone"></i>&nbsp;+91 8743000197
          </a>
          <br />
          <a href="mailto:vanasthali@royalcollectionhotels.com">
            <i className="fa-solid fa-envelope"></i>
            &nbsp;vanasthali@royalcollectionhotels.com
          </a>
        </>
      ),
    },
    {
      question: "Is Vanasthali Jungle Resort, Corbett situated near a river?",
      answer:
        "Yes, Vanasthali Jungle Resort, Corbett, is located near the Kosi River, offering guests a peaceful and scenic experience with the soothing sounds of the river adding to the natural beauty of the resort's surroundings.",
    },
    {
      question:
        "How is Vanasthali Jungle Resort, Corbett better than other resorts?",
      answer:
        "Vanasthali Jungle Resort, Corbett, stands out with its proximity to the Kosi River, offering scenic views and a peaceful atmosphere. The resort provides diverse accommodations, exciting safari experiences, and various activities. Guests can also enjoy the luxury of a private swimming pool at the restaurant. With expert management by Royal Collection Hotels & Resorts Pvt. Ltd., it guarantees a memorable stay in the heart of nature.",
    },
    {
      question:
        "What are the various payment modes accepted at Vanasthali Jungle Resort Corbett?",
      answer:
        "Vanasthali Jungle Resort, Corbett, accepts payments via credit/debit cards, online bank transfers, and UPI.",
    },
    {
      question:
        "Which are the nearest Safari Gates to Vanasthali Jungle Resort Corbett?",
      answer:
        "The nearest safari gates to Vanasthali Jungle Resort, Corbett, are Durga Devi Gate and Jhirna Gate.",
    },
    {
      question: "Do Vanasthali Jungle Resort Corbett allow Pets?",
      answer:
        "Yes, Vanasthali Jungle Resort, Corbett, allows pets, making it a great choice for guests traveling with their furry friends.",
    },
    {
      question: "Do Vanasthali Jungle Resort Corbett have internet service?",
      answer:
        "Yes, Vanasthali Jungle Resort, Corbett, provides internet service to guests, ensuring they stay connected during their stay.",
    },
    {
      question:
        "Are the rooms at Vanasthali Jungle Resort Corbett differently abled friendly?",
      answer:
        "Yes, Vanasthali Jungle Resort, Corbett, offers accommodations that are differently-abled friendly, ensuring comfort and accessibility for all guests.",
    },
    {
      question:
        "What are the room amenities at Vanasthali Jungle Resort, Corbett?",
      answer:
        "The rooms at Vanasthali Jungle Resort, Corbett, include air conditioning, flat-screen TV, Wi-Fi, tea/coffee maker, 24-hour room service, private bathroom, and in-room dining.",
    },
    {
      question: "What are the cancellation policies?",
      answer: (
        <>
          <ul>
            <li>
              <strong>Cancellation before 30 days prior to check-in:</strong> No
              cancellation charges.
            </li>
            <br />
            <li>
              <strong>
                Cancellation between 30 to 15 days prior to check-in:
              </strong>{" "}
              One night pro-rata cancellation charges.
            </li>
            <br />
            <li>
              <strong>Cancellation between 14 days to check-in:</strong> Full
              period of reservation will be charged.
            </li>
            <br />
            <li>
              <strong>
                Amendment/Postponement (7 days prior to check-in):
              </strong>{" "}
              1-night retention charge.
            </li>
            <br />
            <li>
              <strong>No Show:</strong> 100% retention of the entire amount.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <section className="faq-section">
      <h1 className="swipe">
        <i className="fa-regular fa-circle-question">&nbsp;</i>FAQ'S
      </h1>
      <br />
      <div className="faq-container">
        {faqData.map((faq, index) => (
          <div className="faq-question swipe" key={index}>
            <div className="faq-question-container">
              <p>{faq.question}</p>
              <button onClick={() => toggleAnswerVisibility(index)}>
                <i
                  className={`fa-solid ${
                    visibleAnswers[index] ? "fa-minus" : "fa-plus"
                  }`}
                ></i>
              </button>
            </div>
            {visibleAnswers[index] && (
              <div className="faq-answer-container">{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Faq;
