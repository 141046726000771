import React from "react";
import "../css/accommodation.css";

import AccommodationImg1 from "../assets/room/villa/villa1.jpg";
import AccommodationImg2 from "../assets/room/luxury-cottage/luxury-cottage1.jpg";
import AccommodationImg3 from "../assets/room/wooden-cottage/wooden-cottage1.jpg";

const Accommodation = () => {
  return (
    <section className="accommodation-section">
      <h1>Accommodation</h1>
      <br />
      <div className="accommodation-container">
        <div className="accommodation-card">
          <img src={AccommodationImg1} alt="" />
          <div className="accommodation-card-content">
            <h4>Villa</h4>
            <button>Explore</button>
          </div>
        </div>
        <div className="accommodation-card">
          <img src={AccommodationImg2} alt="" />
          <div className="accommodation-card-content">
            <h4>Luxury Cottage</h4>
            <button>Explore</button>
          </div>
        </div>
        <div className="accommodation-card">
          <img src={AccommodationImg3} alt="" />
          <div className="accommodation-card-content">
            <h4>Wooden Cottage</h4>
            <button>Explore</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Accommodation;
