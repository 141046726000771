import React from "react";
import { useNavigate } from "react-router-dom";
import "../css/explore-rooms-section.css";
import RoomCard from "./room-card";
import LuxuryCottage from "../assets/room/luxury-cottage/luxury-cottage1.webp"; 
import Villa from "../assets/room/villa/villa1.jpg"; 
import WoodenCottage from "../assets/room/wooden-cottage/wooden-cottage1.jpg"; 

const ExploreRoomsSection = () => {
  const navigate = useNavigate();
  const rooms = [
    {
      id: 1,
      image: LuxuryCottage,
      title: "Luxury Cottage",
      description:
        "Indulge in luxury with a dream getaway stunning amenities, views, and timeless elegance for an unforgettable experience.",
      price: 4499,
    },
    {
      id: 2,
      image: Villa,
      title: "Villa",
      description:
        "Create lasting memories in a cozy villa, offering comfort, warmth, and beautiful surroundings for the perfect getaway.",
      price: 5499,
    },
    {
      id: 3,
      image: WoodenCottage,
      title: "Wooden Cottage",
      description:
        "Escape to a charming wooden cottage, where rustic charm meets modern comfort, offering a serene retreat in nature's embrace.",
      price: 3499,
    },
  ];

  return (
    <section className="explore-rooms-section">
      <div className="explore-rooms-header">
        <div className="explore-rooms-header-left swipe-left">
          <h2>Explore Our Rooms</h2>
          <h4>Choose a room according to your budget</h4>
        </div>
        <div className="explore-rooms-header-right swipe-right">
          <button
            onClick={() => {
              navigate("/rooms");
            }}
          >
            View All Rooms <i className="fa-solid fa-caret-right"></i>
          </button>
        </div>
      </div>
      <div className="explore-rooms-cards swipe">
        {rooms.map((room, index) => (
          <RoomCard
            key={index}
            image={room.image}
            title={room.title}
            description={room.description}
            price={room.price}
          />
        ))}
      </div>
    </section>
  );
};

export default ExploreRoomsSection;
