import React from 'react'
import { Helmet } from 'react-helmet-async'
import Navbar from '../components/navbar'
import TopBanner from '../components/top-banner'
import Footer from '../components/footer'
import SocialMediaFixed from '../components/socialmedia-fixed'
import WoodenCottageComp from '../components/wooden-cottage-comp'

const WoodenCottagePage = () => {

    return (
        <>
            <Helmet>
                <title>Wooden Cottage - Vanasthali Jungle Resort</title>
                <meta name="description" content="Stay in the cozy Wooden Cottage at Vanasthali Jungle Resort. Surrounded by nature, enjoy a unique blend of comfort and rustic charm in Corbett." />
                <meta name="keywords" content="Wooden Cottage, Vanasthali Jungle Resort, Corbett wooden stay, rustic wooden cottage Corbett, cozy accommodations Corbett, nature stay in Corbett" />
                <link rel="canonical" href="https://vanasthalicorbett.com/rooms/wooden-cottage" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "HotelRoom",
                        "name": "Wooden Cottage",
                        "url": "https://vanasthalicorbett.com/rooms/wooden-cottage",
                        "telephone": "+91 8743000197",
                    })}
                </script>
            </Helmet>



            {<TopBanner />}
            {<Navbar />}
            {<WoodenCottageComp />}
            {<Footer />}
            {<SocialMediaFixed />}
        </>
    )
}

export default WoodenCottagePage
