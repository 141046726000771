import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import TopBanner from '../components/top-banner';
import SocialMediaFixed from '../components/socialmedia-fixed';
import GuestReviews from '../components/guest-reviews';
import Faq from '../components/faq';

const FaqPage = () => {
    return (
        <>
            <Helmet>
                <title>FAQs - Vanasthali Jungle Resort</title>
                <meta name="description" content="Find answers to frequently asked questions about Vanasthali Jungle Resort in Jim Corbett. Get information about booking, accommodations, activities, policies, and more." />
                <meta name="keywords" content="Vanasthali Jungle Resort FAQ, Jim Corbett resort questions, frequently asked questions Corbett, resort booking info, accommodation FAQs, activities at Vanasthali, resort policies, Jim Corbett travel information, Jim Corbett safari questions" />
                <link rel="canonical" href="https://vanasthalicorbett.com/faq" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Hotel",
                        "name": "FAQs - Vanasthali Jungle Resort",
                        "telephone": "+91 8743000197",
                        "url": "https://vanasthalicorbett.com/faq"
                    })}
                </script>
            </Helmet>

            <TopBanner />
            <Navbar />
            <Faq />
            <GuestReviews />
            <Footer />
            <SocialMediaFixed />
        </>
    );
};

export default FaqPage;
