import React from "react";
import "../css/introduction-section.css";
import IntroductionImg from "../assets/gallery/11.jpeg";

const IntroductionSection = () => {
  return (
    <section className="introduction-section">
      <div className="introduction-section-right swipe">
        <h1 className="after">
          Welcome To <br />
          <h1>Riverside Luxury Resort</h1>
        </h1>
        <p>
          Vanasthali Jungle Resort Jim Corbett, Ramnagar offers air-conditioned rooms with modern amenities, free WiFi, and a restaurant. Located 1.5 km from the main road amidst the jungle, it’s near a seasonal river and 46 km from Pantnagar Airport. Guests can enjoy cycling, a playground, and a continental breakfast.
        </p>
      </div>
      <div className="introduction-section-left swipe">
        <img
          src={IntroductionImg}
          alt="Riverside Luxury Resort"
          loading="eager"
          width="100%"
          height="auto"
        />
      </div>
    </section>
  );
};

export default IntroductionSection;
